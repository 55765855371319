import { NgxOtpInputConfig } from 'ngx-otp-input';

export const otpInputConfig: NgxOtpInputConfig = {
  otpLength: 6,
  autofocus: true,
  classList: {
    inputBox: 'motp-input-box',
    input: 'motp-input',
    inputFilled: 'motp-input-filled',
    inputDisabled: 'motp-input-disabled',
    inputSuccess: 'motp-input-success',
    inputError: 'motp-input-error'
  }
};

export const verifyPhoneOtpConfig: NgxOtpInputConfig = {
  otpLength: 5,
  autofocus: true,
  classList: {
    inputBox: 'motp-input-box',
    input: 'motp-input',
    inputFilled: 'motp-input-filled',
    inputDisabled: 'motp-input-disabled',
    inputSuccess: 'motp-input-success',
    inputError: 'motp-input-error'
  }
};

export const cSSO_val: any[] = [
  { img: "url('../../../../assets/icons/Google Logo.svg')", name: 'Google' },
  { img: "url('../../../../assets/icons/Apple logo.svg')", name: 'Apple' }
];

export const cPageConfig = {
  heading: 'Log in',
  buttonText: 'Log in',
  subheading1: "Don't have an account?",
  subheading2: 'Sign up',
  firstnameField: false,
  lastnameField: false,
  emailField: true,
  passField: true,
  passField2: false,
  form: true,
  tnC: false,
  SSO: true,
  showLogin: false,
  backtologin: false
};
export const userProfile = {
  email: '',
  pass: '',
  pass2: '',
  fname: '',
  lname: '',
  termsAgreement: false,
  betaAgreement: false,
  otp: '',
  phone: '',
  email_opt_in: false,
  sms_opt_in: false,
  country: '',
  zipCode: '',
  subtenant:'',
  ageRange: '',
  gender: '',
  authCode:''
};

export enum SignupTenantPageCount {
  getting_started,
  create_account=1,
  personal_info,
  contact_detail,
}

export enum SignupSelfRegPageCount {
  getting_started,
  create_account=1,
  email_OTP,
  personal_info,
  contact_detail,
}

export enum SignupSelfRegAuthCodePageCount {
  getting_started,
  auth_code=1,
  create_account,
  email_OTP,
  personal_info,
  contact_detail,
}

export enum SocialLoginSignupAuthCodePageCount {
  getting_started,
  auth_code=1,
  personal_info,
  contact_detail,
}

export enum SocialLoginSignupPageCount {
  getting_started,
  personal_info=1,
  contact_detail,
}

export enum SubTenantSignupPageCount  {
  getting_started,
  subtenant=1,
  create_account,
  personal_info,
  contact_detail,
}

export enum SubtenantSignupSelfRegPageCount {
  getting_started,
  subtenant=1,
  create_account,
  email_OTP,
  personal_info,
  contact_detail,
}

export enum SubtenantSocialLoginSignupPageCount {
  getting_started,
  subtenant=1,
  personal_info,
  contact_detail,
}

export const pass_strength: any = {
  yes: '../../../../assets/images/check.svg',
  no: '../../../../assets/images/passdot.svg'
};
export const pass_strength_param = {
  min8: 'no',
  lower: 'no',
  upper: 'no',
  number: 'no',
  special: 'no'
};
export const error_msg = {
  img: '../../../../assets/images/Notification Error.svg',
  class: 'msgError',
  typ: 'error',
  heading: '',
  text: ''
};
export const warning_msg = {
  img: '../../../../assets/images/Notification Error.svg',
  class: 'msgWarning',
  typ: 'error',
  heading: '',
  text: ''
};
export const success_msg = {
  img: '../../../../assets/images/Notification Positive.svg',
  class: 'msgSuccess',
  typ: 'success',
  heading: '',
  text: ''
};

export interface toastMsg {
  class:string;
  text:string;
  heading:string;
  img:string;
  typ?:string
}

const p_personal_info ={
  heading: 'Personal information',
  subheading1: 'We’ll need this info so we can personalize your experience.',
  subheading2: ''
}
export const p_create_account = {
  heading: 'Create account',
  subheading1: 'Already have an account?',
  subheading2: 'Log in'
}
const p_phone_no = {
  heading: 'What’s your phone number?',
  subheading1: '',
  subheading2: ''
}

const p_sms={
  heading: 'Enter the code',
  subheading1: 'Enter the 5-digit the code sent to you via SMS',
  subheading2: ''
}
const p_email_otp={
  heading: 'Enter the code',
  subheading1: 'Enter the 5-digit the code sent to you via email',
  subheading2: ''
}
const p_empty={
  heading:'',
  subheading1: '',
  subheading2: ''
}

export const p_forgot_pass={
  heading:'Forgot password',
  subheading1: 'We’ll email you a code to reset your password.',
  subheading2: ''
}

export const p_reset_password={
  heading:'Reset password',
  subheading1: 'We emailed you a code to reset your password.',
  subheading2: ''
}
// tenant name based
export const p_auth_code={
  heading:'Enter your signup code',
  subheading1: '${tenant} is members-only—it’s how we offer such great deals. Enter the signup code you were provided.',
  subheading2: ''
}

export const SocialLoginSignupPageContent = [
  p_personal_info,
  p_phone_no
];

export const SocialLoginSignupAuthCodePageContent = [
  p_auth_code,
  p_personal_info,
  p_phone_no
];

export const SignupPageContent = [p_create_account,...SocialLoginSignupPageContent];

export const SelfRegSignupPageContent = [
  p_create_account,
  p_email_otp,
  p_personal_info,
  p_phone_no,
];

export const SignupAuthCodePageContent = [p_auth_code,...SelfRegSignupPageContent];

export const SubTenantSocialLoginSignupPageContent = [
  p_empty,
  p_personal_info,
  p_phone_no,
];

export const SubTenantSignupPageContent = [p_empty,p_create_account,...SocialLoginSignupPageContent];

export const SubTenantSelfRegSignupPageContent = [
  p_empty,
  p_create_account,
  p_email_otp,
  p_personal_info,
  p_phone_no,
];

export enum signupSubmitType {
  socialLogin,
  email,
  verify_email
}

export const progressBars = {
  tenant_email:3,
  subtenant_email:3,
  selfReg_email:4,
  subtenant_selfReg_email:4,
  socialLogin:2,
  subtenant_socialLogin:2,
  authCode_selfReg_email:5,
  authCode_socialLogin:3,
}

export const toastSessionKey = "toast-to-show"
