import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { CommonService, LaasyCookieService } from '@laasy/common-layout';
import { ShopService } from '@laasy/common-layout/shop';
import { first } from 'rxjs';
import { AuthConnectService } from 'src/app/modules/auth/service/auth-connect.service';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { environment } from 'src/environment/environment';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  ignoreRedirectURLs=['clearcache']

  constructor(
    private laasyCookieService: LaasyCookieService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private authconnect:AuthConnectService,
    private comService:CommonService
  ) {}

  async canActivate(): Promise<boolean> {
    return await this.isAuthenticated();
  }

  async canActivateChild(): Promise<boolean> {
    return await this.isAuthenticated();
  }

  private async isAuthenticated() {
    const currentSession = await this.authenticationService.GetCurrentSession();

    if (currentSession !== null) {
      const email = this.comService.getCognitoUserdataByKey()?.email
      const accountStatus = await this.authconnect.checkAccount(email);
      if (!accountStatus.isExist){
        // if session active but user deleted
        if(accountStatus.isPasswordBased)
          this.router.navigate(['/auth/signin']);
        // user not filled form
        else if(environment.idpEnabled)
          this.router.navigateByUrl('/auth/single-sign-on?isLoggedIn=true');
        else
          this.router.navigateByUrl('/auth/ssosignup?isLoggedIn=true');
        return false
      }
      this.comService.getUserContext().pipe(first()).subscribe()
      this.comService.getUserCashback().pipe(first()).subscribe()
      return true;
    }

    this.laasyCookieService.removeContext();
    this.laasyCookieService.removeUserAPICache();
    const redirectURL = btoa(unescape(encodeURIComponent(window.location.href)))
    const isHomeLanding = window.location.href === window.location.origin || window.location.href === window.location.origin+'/'
    if(!(this.ignoreRedirectURLs.some(x=>window.location.href.includes(x)) || isHomeLanding))
      this.router.navigateByUrl(`/auth-redirect?redirectURL=${redirectURL}`)
    else
      this.router.navigateByUrl('/auth/signin')
    return false;
  }
}
