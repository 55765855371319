import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { TenantService } from './shared/services/tenant/tenant.service';
import { Location } from '@angular/common';
import { environment } from 'src/environment/environment';
import {
  updateCSS,
  getHostName,
  AnalyticsService,
  ScriptLoaderService,
} from '@laasy/common-layout';
import { replaceColor } from 'lottie-colorify';
import LoginLoaderLottieAnimation from '../assets/LoginLoaderLottie.json';
import { LoginLoaderService } from './shared/services/common/loader.service';
import { Utils } from './shared/utilities/utils';
import { astraStyle } from './shared/style/astraStyle';
import { AuthenticationService } from './shared/services/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  title = 'LaaSy';
  favIcon: any;

  layoutComponent: string = 'app-layout';
  @ViewChild('toast', { read: ElementRef, static: false }) toastElementRef: ElementRef | undefined;
  private observerInitialized = false;
  showLoginLoader = false;
  hostName: string = ''
  loginLoaderAnimation: any = LoginLoaderLottieAnimation


  constructor(
    private loaderService: LoginLoaderService,
    private location: Location,
    private tenantService: TenantService,
    private readonly authenticationService: AuthenticationService,
    private scriptService:ScriptLoaderService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.tenant_config();
    this.loaderService.isLoginLoader.asObservable().subscribe(show => {
      this.showLoginLoader = show;
    });
    this.addAstraStyle();
    setInterval(() => this.refreshToken(), environment.refreshTokenInterval * 60 * 1000)
  }

  async refreshToken() {
    const currentSession = await this.authenticationService.GetCurrentSession()
    if (currentSession !== null) {
      const idTokenExpire = currentSession.getIdToken().getExpiration()
      sessionStorage.setItem("token_expire_datetime", idTokenExpire)
    }
  }

  tenant_config() {
    this.favIcon = document.querySelector('#appIcon');
    if (!sessionStorage.getItem('mytenant'))
      updateCSS({
        logo: '../assets/images/LaaSyClientLogo.png',
        loginBackground: '../assets/images/auth-background.svg',
        color1: '#FDDA01'
      });
    this.getTenantsDomain();
  }

  getTenantsDomain() {
    this.hostName = getHostName(this.location)
    this.tenantService.getTenantAppConfig().subscribe((data: any) => {
      let lottieAnimation = LoginLoaderLottieAnimation;

      if (data?.color1)
        lottieAnimation = replaceColor(
          [255, 200, 46],
          Utils.hexToRgb(data?.color1),
          lottieAnimation
        )
      if (data?.color3)
        lottieAnimation = replaceColor(
          [255, 0, 0],
          Utils.hexToRgb(data?.color3),
          lottieAnimation
        )

      this.loginLoaderAnimation = lottieAnimation
    })
  }



  ngAfterViewChecked(): void {
    const analytics = this.analyticsService.getSegmentAnalytics();
    if (analytics)
      this.observerInitialized = this.analyticsService.analytics_capture_toast(
        analytics,
        this.observerInitialized,
        this.toastElementRef
      );
  }

  addAstraStyle() {
    // Adding style this way coz css-purge is removing id selectors from final css file
    const styleElement = document.createElement('style');
    styleElement.textContent = astraStyle
    document.head.appendChild(styleElement);
  }

}
